import { createContext, useState } from "react";

const DataCamionesContext = createContext();

const CamionesProvider = ({ children }) => {
    const [listaCamiones, setListaCamiones] = useState([]);

    // Función para agregar un nuevo elemento al array
    const addCamiones = (newData) => {
        if (!listaCamiones.includes(newData)) {
            setListaCamiones(newData);
        } else {
            console.log(`El elemento ya está en el array.`);
        }
    };

  return <DataCamionesContext.Provider value={ { listaCamiones,addCamiones } }>{children}</DataCamionesContext.Provider>;
};

export { CamionesProvider };
export default DataCamionesContext;