import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import { NavLink } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { FaBars } from 'react-icons/fa';
import usePageSelected from "../../../hooks/usePageSelected";

function Menu({ pag }) {

  const logoFAW = 'faw-logo-azul.webp'

  // referencia al tag header (padre del menu) y calcular alto
  const menu = useRef(null);
  useEffect(() => {
    if (menu.current) {
      const { height } = window.getComputedStyle(menu.current);
      document.documentElement.style.setProperty('--height-menu', height);
    }
  }, []);

  // se captura un valor que ayuda a saber que pagina esta activa (home, product, about)
  const { home,contacto} = usePageSelected(pag)
  
  // se obtienen las dimensiones de la viewport
  const windowDimensions = useWindowDimensions()
  const heightViewport = windowDimensions.height
  document.documentElement.style.setProperty('--vpHeight',heightViewport) // se setea la variable --vpHeight en CSS


  // se captura [seccion actual, logo correpondiente segun color]
  // const [currentSection,logoSrc] = useScrollPage()

  // se referencia el nav bar y se le aplica o no aplica la clase que hace el toggle Menu
  const [togMenuState,setTogMenuState] = useState(false)
  const navRef = useRef()
  const btnCloseToggle = useRef()
  const showNavBar = () => {
      const vpWidth = windowDimensions.width
      if (vpWidth <= 1100){
        navRef.current.classList.toggle('responsive-nav-bar')
        btnCloseToggle.current.classList.toggle('nav-close-btn')
        setTimeout(() => {
          !togMenuState ? setTogMenuState(true) : setTogMenuState(false)
        }, 500);
      }
  }

  const showNavBarLink = () => {
    window.scrollTo(0, 0);
    const vpWidth = windowDimensions.width
    if (vpWidth <= 1100){
      !togMenuState ? setTogMenuState(true) : setTogMenuState(false)
      navRef.current.classList.toggle('responsive-nav-bar')
      btnCloseToggle.current.classList.toggle('nav-close-btn')
    }
}

  return (
    <header ref={menu} className='menu'>
      {home &&
        <a href="#seccion__inicio" className="logotipo">
          <img className="logo-faw" src={require(`../../../assets/logos/${logoFAW}`)} alt="Logo FAW TRUCKS | PANAMÁ" />
          <h1 className="faw-title">
            FAW TRUCKS<span>|</span>PANAMÁ
          </h1>
        </a>
      }
      {!home &&
        <NavLink to="/" className="logotipo">
          <img className="logo-faw" src={require(`../../../assets/logos/${logoFAW}`)} alt="Logo FAW TRUCKS | PANAMÁ" />
          <h1 className="faw-title">
            FAW TRUCKS<span>|</span>PANAMÁ
          </h1>
        </NavLink>
      }
      <div ref={btnCloseToggle} className="nav-close-btn-init" onClick={showNavBar}> </div>
      <nav ref={navRef} className="menu-list-container">
        <div className="menu-list">
          <a className='a-opciones' href="/#seccion__productos" onClick={showNavBar}>Camiones</a>
          <a className='a-opciones' href="/#seccion__postventa" onClick={showNavBar}>Postventa</a>
          {/* <a href="/#seccion__nosotros">Sucursales</a> */}
          <NavLink className='a-opciones' to="/nosotros" onClick={showNavBarLink}>Nosotros</NavLink>
          <button className="btn-cotizar">
            <a className='a-cotizar' href={contacto} onClick={showNavBar}>Cotizar</a>
          </button>
        </div>
      </nav>
      <button className="nav-btn" onClick={showNavBar}>
        <FaBars/>
      </button>
    </header>
  );
}

export default Menu;
