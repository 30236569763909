import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import './index.css';
import App from './App';


const Home = lazy(() => import('./pages/Home/Home'))
const Producto = lazy(() => import('./pages/Producto/Producto'))
const Nosotros = lazy(() => import('./pages/Nosotros/Nosotros'))
const TerminosYCondiciones = lazy(() => import('./pages/TerminosYCondiciones/TerminosYCondiciones'))
const NotFound = lazy(() => import('./pages/NotFound/NotFound'))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>

        <Route path='/' element={<App pag={'home'}/>}>
          <Route index element={<Home/>}/>
          <Route path='product' element={<Producto/>}/>
          <Route path='terminos-y-condiciones' element={<TerminosYCondiciones/>}/>
          <Route path='*' element={<Home/>}/>
        </Route>

        <Route path='/product' element={<App pag={'producto'}/>}>
          <Route path=':camion' element={<Producto/>}/>
          <Route path='terminos-y-condiciones' element={<TerminosYCondiciones/>}/>
          <Route path='*' element={<Home/>}/>
        </Route>

        <Route path='/nosotros' element={<App pag={'nosotros'}/> }>
          <Route index element={<Nosotros/>}/>
          <Route path='terminos-y-condiciones' element={<TerminosYCondiciones/>}/>
          <Route path='*' element={<Home/>}/>
        </Route>

        <Route path='/terminos-y-condiciones' element={<App pag={'terminos-y-condiciones'}/> }>
          <Route index element={<TerminosYCondiciones/>}/>
          <Route path='*' element={<Home/>}/>
        </Route>

        <Route path='/' element={<App pag={'terminos-y-condiciones'}/>}>
          <Route path="/not-found" element={<NotFound/>}/>
        </Route>

      </Routes>
    </BrowserRouter>
  </React.StrictMode>
); 