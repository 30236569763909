import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Menu from "./components/components-reutilizables/Header/Header";
import { CamionesProvider } from "./context/Camiones.context";
import ReactGA from "react-ga4";
ReactGA.initialize("G-3Q7E1BSN57");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

function App({ pag }) {
  return (
    <>
      <CamionesProvider>
        <Menu pag={pag} />
        <Suspense>
          <Outlet />
        </Suspense>
      </CamionesProvider>
    </>
  );
}

export default App;
