import { useState, useEffect} from "react";

const usePageSelected = (pag) => {
    const [pagina, setPagina] = useState({});
        useEffect(() => {
            if (pag === 'home') {
                setPagina({
                        home: true,
                        contacto: '#seccion__contacto'
                    });
            } else if (pag === 'producto') {
                setPagina({
                        home: false,
                        contacto: '#seccion__contacto'
                    });
            } else {
                setPagina({
                        home: false,
                        contacto: '/'
                    });
            }
        }, [pag]);

    return pagina
}

export default usePageSelected 